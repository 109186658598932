$(function () {

  // let svg_next = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="30"  viewBox="0 0 18 30"><defs><path d="M30.7 10.76L19.97 21.5 9.24 10.76A2.5 2.5 0 005.7 14.3l12.5 12.5a2.5 2.5 0 003.54 0l12.5-12.5a2.5 2.5 0 10-3.54-3.54z" id="a2"/></defs><use fill="currentColor" xlink:href="#a2" transform="matrix(0 1 1 0 -10 -5)" fill-rule="evenodd"/></svg>';
  // let svg_prev = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="30" viewBox="0 0 18 30"><defs><path d="M30.7 10.76L19.97 21.5 9.24 10.76A2.5 2.5 0 005.7 14.3l12.5 12.5a2.5 2.5 0 003.54 0l12.5-12.5a2.5 2.5 0 10-3.54-3.54z" id="a"/></defs><use fill="currentColor" xlink:href="#a" transform="rotate(90 16.5 11.5)" fill-rule="evenodd"/></svg>';
  
  let svg_next = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 .59L5.59 2l10 10-10 10L7 23.41 18.41 12z" fill="currentColor" fill-rule="evenodd"/></svg>';
  let svg_prev = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 .59L18.41 2l-10 10 10 10L17 23.41 5.59 12z" fill="currentColor" fill-rule="evenodd"/></svg>';
  

  if ($(".hero").is('.is-fullwidth')){
    svg_next = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16 0a16 16 0 110 32 16 16 0 010-32zm0 2.67a13.33 13.33 0 100 26.66 13.33 13.33 0 000-26.66zm-4.55 8l1.88-1.89L20.55 16l-7.22 7.22-1.88-1.89L16.78 16l-5.33-5.33z" fill="currentColor" fill-rule="evenodd"/></svg>';
    svg_prev = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16 0a16 16 0 100 32 16 16 0 000-32zm0 2.67a13.33 13.33 0 110 26.66 13.33 13.33 0 010-26.66zm4.55 8l-1.88-1.89L11.45 16l7.22 7.22 1.88-1.89L15.22 16l5.33-5.33z" fill="currentColor" fill-rule="evenodd"/></svg>';
  }
  
  $('.hero__slider').slick({
    nextArrow: `
      <button class="slick-next slick-arrow" aria-label="Next" type="button">
        ${svg_next}        
      </button>`,
    prevArrow: `
      <button class="slick-prev slick-arrow" aria-label="Previous" type="button">
        ${svg_prev}
      </button>`,
  }).css('opacity', 1);

  if ($(".hero").is('.is-fullwidth')){
    $(".hero .slick-arrow").detach().appendTo(".hero");
  }

  $('[data-hero-button-viewphotos]').on('click', function(){
    console.log('start gallery', window.js_gallery_feed);

    $.fancybox.defaults.buttons = ["zoom", "thumbs", "close" ];
    $.fancybox.open(window.js_gallery_feed, {
      padding : 0   
    });
  });

});