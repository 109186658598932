$(function () {

  var currentSlideIndex = 0;
  var carousel = $('.availability-carousel');
  var slidesContainer = carousel.find('.availability-card-group-container');
  var slides = slidesContainer.children('.availability-card-group');
  var arrowLeft = carousel.find('.availability-carousel__arrow-left');
  var arrowRight = carousel.find('.availability-carousel__arrow-right');
  // var indicators = gallery.find('.carousel__indicator');

  // Define the click handlers
  arrowLeft.click(function () {
    console.log('left');
    var index = getPrevSlideIndex();
    displayImage(index)
    // updateDots(index);
  });
  arrowRight.click(function () {
    console.log('right');
    var index = getNextSlideIndex();
    displayImage(index)
    // updateDots(index);
  });
  // indicators.click(function () {
  //   var index = $(this).index();
  //   displayImage(index)
  //   updateDots(index);
  // });

  $(window).resize(function () {
    displayImage(currentSlideIndex);
  });

  function getNextSlideIndex() {
    if (currentSlideIndex === slides.length - 1) {
      return 0;
    }

    return currentSlideIndex + 1;
  }

  function getPrevSlideIndex() {
    if (currentSlideIndex === 0) {
      return slides.length - 1;
    }

    return currentSlideIndex - 1;
  }

  // Applies the correct 'left' property to the images container
  // to provide a sliding carousel effect.
  function displayImage(index) {
    var width = slidesContainer.width();
    slidesContainer.css('left', -(width * index));
    currentSlideIndex = index;
  }

  // Updates the 'selected' class for the dots.
  function updateDots(index) {
    indicators.removeClass('carousel__indicator--selected');
    $(indicators.get(index)).addClass('carousel__indicator--selected');
  }

});